import React, { useState, useEffect } from 'react';
import InternetIcon from './internet-icon.svg';
import './styles.less';
import './App.css';
import Amplify, { API } from 'aws-amplify';
import { awsconfig, cfIpv6Endpoint } from './aws-exports';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import ClickToCopy from './ClickToCopy';

Amplify.configure(awsconfig);

interface ICloudFrontIpv6Response {
  ipv6: null | undefined | string;
}
interface IVisitorIpInformation {
  ipv4: string;
  additionalIspInformation: {
    as: string;
    city: string;
    country: string;
    countryCode: string;
    isp: string;
    org: string;
    timezone: string;
  };
}

function App() {
  const [visitorIpInformation, setVisitorIpInformation] = useState<IVisitorIpInformation | null>(null);
  const [ipv6Information, setIpv6Information] = useState<ICloudFrontIpv6Response | null>({ ipv6: null });

  // Get visitor information from backend
  useEffect(() => {
    API.get('backend', '/', {})
      .then((res) => {
        setVisitorIpInformation(res);
        document.title = res.ipv4;
      })
      .catch((err) => console.log('error', err));
    axios
      .get(cfIpv6Endpoint)
      .then((res) => {
        setIpv6Information(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div className="Logo-Header">
          <img src={InternetIcon} className="Internet-Icon" alt="logo" />
        </div>
      </header>
      <div className="Content">
        <ClickToCopy>
          <code>{visitorIpInformation ? visitorIpInformation.ipv4 : <LoadingOutlined style={{ fontSize: 24 }} spin />}</code>
        </ClickToCopy>
        {ipv6Information?.ipv6 ? (
          <div className="code-ipv6">
            <ClickToCopy><code>{ipv6Information.ipv6}</code></ClickToCopy>
          </div>
        ) : null}
        <div className="isp-info">
          <p>
            {visitorIpInformation?.additionalIspInformation.as}
            <br />
            {visitorIpInformation?.additionalIspInformation.country}
          </p>
        </div>
      </div>
      <div className="App-Footer">
        Brought to you by{' '}
        <a href="https://cv.viktorgoldberg.de" target="_blank" rel="noreferrer noopener">
          vgold
        </a>
      </div>
    </div>
  );
}

export default App;

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Route paths
import App from './App';
// import RedirectComp from './RedirectComp';

const AppRouter = (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
    </Routes>
  </BrowserRouter>
);

export default AppRouter;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/antd/dist/antd.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n.Logo-Header {\n  margin-bottom: 3em;\n}\nh5 {\n  color: white;\n}\ncode {\n  color: white;\n}\nsvg {\n  color: #40a9ff;\n}\np {\n  color: #91d5ff;\n  font-size: 7pt;\n}\n@media (min-width: 800px) {\n  p {\n    font-size: 10pt;\n  }\n}\n@media (min-width: 1400px) {\n  p {\n    font-size: 12pt;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/styles.less"],"names":[],"mappings":"AAAA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F;AAE7F;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;EACd,cAAc;AAChB;AACA;EACE;IACE,eAAe;EACjB;AACF;AACA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n@import '~antd/dist/antd.css';\n.Logo-Header {\n  margin-bottom: 3em;\n}\nh5 {\n  color: white;\n}\ncode {\n  color: white;\n}\nsvg {\n  color: #40a9ff;\n}\np {\n  color: #91d5ff;\n  font-size: 7pt;\n}\n@media (min-width: 800px) {\n  p {\n    font-size: 10pt;\n  }\n}\n@media (min-width: 1400px) {\n  p {\n    font-size: 12pt;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import ReactDOM from 'react-dom';
import './index.css';
import AppRouter from './Router';
import * as serviceWorker from './serviceWorker';

const router = AppRouter
const container = document.getElementById('root')

ReactDOM.render(router, container);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

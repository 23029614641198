import React, {useState} from "react"
import { Popover } from "antd"
import { CheckCircleTwoTone, CopyTwoTone } from "@ant-design/icons"
import "antd/dist/antd.css";

const ClickToCopy = (props: any) => {
    const [clicked, setClicked ] = useState(false)
    const [hovered, setHovered] = useState(false)


    const hide = () => {
        setClicked(false)
        setHovered(false)
    }

    const handleHoverChange = (visible: boolean) => {
        setClicked(false)
        setHovered(visible)
    }

    const handleClickChange = async (visible: boolean) => {
        setClicked(visible)
        setHovered(false)
        await navigator.clipboard.writeText(props.children.props.children)
        setTimeout(() => {
            hide()
        }, 1000);
    }

    const hoverContent = <div>Click to copy <CopyTwoTone /></div>
    const clickContent = <div>Copied! <CheckCircleTwoTone twoToneColor="#52c41a"/></div>

    return(
        <Popover
            placement="right"
            content={hoverContent} 
            trigger="hover"
            visible={hovered}
            onVisibleChange={(visible) => handleHoverChange(visible)}
        >
            <Popover
                placement="right"
                content={clickContent}
                trigger="click"
                visible={clicked}
                onVisibleChange={(visible) => handleClickChange(visible)}
            >
            {props.children}
            </Popover>
        </Popover>
    )
}

export default ClickToCopy
export const awsconfig = {
  aws_project_region: 'us-east-1',
  aws_cloud_logic_custom: [
    {
      name: 'backend',
      endpoint: 'https://vvsxqaljsi.execute-api.us-east-1.amazonaws.com/prod',
      region: 'us-east-1',
    },
  ],
};

export const cfIpv6Endpoint: string = 'https://d2th11xcbk7ix1.cloudfront.net/';
